import React from "react";
import Apply from "../../components/templates/landing/Apply";
import Dedication from "../../components/templates/landing/Dedication/Dedication-RenewApproval";
import About from "../../components/templates/landing/About";
import DetailsButton from "../../components/templates/landing/DetailsButton";
import Layout from "../../layouts/landing";
import RenewApproval from "../../components/templates/landing/Apply/RenewApproval";
import image from "../../assets/landing/about.png";
import dedicationImg from "../../assets/landing/dedication-renew-approval.png";
import { ReactComponent as Logo } from "../../assets/landing/logo-small.svg";
import { Theme } from "./styles";

const title = (
  <>
    <Logo /> WE ARE ISTANTE LENDING
  </>
);

const Landing = () => {
  return (
    <Theme>
      <Layout>
        <Apply sectionOne={<RenewApproval />} />
        <Dedication image={dedicationImg} />
        <About image={image} title={title} companyName="Istante Lending" />
        <DetailsButton />
      </Layout>
    </Theme>
  );
};

export default Landing;
