import styled from "styled-components";

export const Theme = styled.div`
  .section-apply .subheading {
    background: #00ce9d;
  }
  .form-wrapper {
    button[type="submit"] {
      background: #be1e2d;
      border: 1px solid #be1e2d;
    }

    .button-secondary {
      border: 1px solid #be1e2d;
      color: #be1e2d;
    }
  }

  .section-dedication {
    .background {
      @media screen and (max-width: 550px) {
        &:before {
          background: #1571b2;
        }
      }
    }
  }

  .section-about {
    .logo-wrapper {
      color: #1671b2;
    }
  }

  .details-button {
    background: #00ce9d;
  }
`;
