import React from "react";
import Apply from "../../components/templates/landing/Apply";
import Dedication from "../../components/templates/landing/Dedication/Dedication-Approved";
import About from "../../components/templates/landing/About";
import DetailsButton from "../../components/templates/landing/DetailsButton";
import Layout from "../../layouts/landing";
import Approval from "../../components/templates/landing/Apply/Approval";
import aboutImage from "../../assets/landing/about.png";
import { Theme } from "./styles";
import imgFamily from "../../assets/landing/dedication.png";
import { ReactComponent as Logo } from "../../assets/landing/logo-small.svg";

const title = (
  <>
    <Logo /> WE ARE ISTANTE LENDING
  </>
);

const Landing = () => {
  return (
    <Theme>
      <Layout>
        <Apply sectionOne={<Approval />} />
        <Dedication image={imgFamily} />
        <About image={aboutImage} title={title} companyName="Istante Lending" />
        <DetailsButton />
      </Layout>
    </Theme>
  );
};

export default Landing;
